import { ApplicationInsights } from "@microsoft/applicationinsights-web";

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: "1457ed76-252d-4136-b31a-b058f9d46165",
    enableAutoRouteTracking: true, // automatically track page views
  },
});

appInsights.loadAppInsights();

export { appInsights };
