import React, { useEffect, useState } from "react";
import { Box, Link, Image, Text, Flex } from "@chakra-ui/react";
import fetchJsonData from "./JsonData";
import { appInsights } from "../appInsights";

const Footer = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      const fetchedData = await fetchJsonData();
      setData(fetchedData);
    };
    loadData();
  }, []);

  const handleFooterLogoClick = () => {
    appInsights.trackEvent({
      name: "Footer",
      properties: {
        buttonName: "footerLogo",
      },
    });
  };

  const handleFooterLinkClick = (id) => {
    appInsights.trackEvent({
      name: "Footer",
      properties: {
        buttonName: "footerLinkbutton",
        linkId: id,
        linkHref: data.footerLinks[id].targetUrl,
        linkLabel: data.footerLinks[id].label,
      },
    });
  };
  if (
    !data ||
    !data.footerLinks ||
    !data.footerLinks[0] ||
    !data.footerLinks[1]
  ) {
    return null; // Return null if data or links are not available yet
  }

  return (
    <Box bg="gray.100" py={6} backgroundColor={data.footerBackgroundColor}>
      <Flex direction="column" align="center" textAlign="center">
        {/* Footer Logo */}
        <Box mb={4}>
          <Link
            href={data.customerPageUrl}
            isExternal
            onClick={() => handleFooterLogoClick()}
          >
            <Image
              src={data.mainFooterLogo}
              alt="Footer Logo"
              maxWidth="150px"
            />
          </Link>
        </Box>

        {/* Footer Credential */}
        <Text fontSize="sm" color={data.footerTextColor}>
          {data.footerCredential}
        </Text>

        {/* Privacy and Rights Links */}
        <Flex mt={4} gap={2} fontSize="sm" color={data.footerTextColor}>
          {data.footerLinks[0] && (
            <Link
              href={data.footerLinks[0].targetUrl}
              isExternal
              onClick={() => handleFooterLinkClick(0)}
            >
              {data.footerLinks[0].label}
            </Link>
          )}
          {data.footerLinks[1] && (
            <Link
              href={data.footerLinks[1].targetUrl}
              isExternal
              onClick={() => handleFooterLinkClick(1)}
            >
              {data.footerLinks[1].label}
            </Link>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default Footer;
