import React, { useEffect, useState } from "react";
import { Box, Flex, Image, Link } from "@chakra-ui/react";
import fetchJsonData from "./JsonData";
import { appInsights } from "../appInsights";

const Header = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      const fetchedData = await fetchJsonData();
      setData(fetchedData);
    };
    loadData();
  }, []);

  const handleHeaderLogoClick = () => {
    appInsights.trackEvent({
      name: "Header",
      properties: {
        buttonName: "headerLogo",
      },
    });
  };

  return (
    <Box>
      {data && (
        <Box bg={data.headerBackgroundColor} width="fit-content" p={4}>
          <Flex justifyContent="space-between">
            {/* Logo Section */}
            <Link
              href={data.customerPageUrl}
              isExternal
              onClick={() => handleHeaderLogoClick()}
            >
              <Image
                src={data.headerLogo}
                alt="Logo"
                width={{ base: "22vw", md: "15vw", lg: "7vw" }}
                objectFit="contain"
              />
            </Link>
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default Header;
